/******* screen-large.less *******/
/*
brakpoints chosen after font-sizes, areas change at different breakpoints
*/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-large.less 2013-1-16 *******/
.footer {
  position: relative;
  padding: 56px 0 110px;
}
.tel,
.vcardemail {
  width: 18.6em;
  margin-right: 30px;
}
.fn {
  margin-top: 40px;
}
#cmsbox {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.mobile-navigation {
  display: none;
}
.menu {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0.55em 2.63em;
}
.menu:after {
  right: 2.5em;
  left: 2.5em;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-layout2 .main > .unit:last-child {
  margin-bottom: 90px;
}
.unit {
  font-size: 20px;
  line-height: 1.3;
}
.unit h2 {
  font-size: 16px;
  line-height: 1.125;
  letter-spacing: 1pt;
}
.pure:after,
.slim:after {
  width: 70px;
  margin-top: 1.4em;
}
.loud.loud.loud {
  font-size: 30px;
  line-height: 1.27;
}
.cb-layout1 .part > p {
  max-width: 35em;
}
.cb-layout1 .seam.wide:first-child {
  padding-top: 56px;
  margin-bottom: 50px;
}
.cb-layout1 .slim {
  margin-bottom: 0;
}
.cb-layout1 .slim:last-child {
  margin-bottom: 110px;
}
.cb-layout1 .slim .part > p {
  max-width: none;
}
.cb-layout1 .slim .loud {
  padding: 0;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/*# sourceMappingURL=./screen-large.css.map */